.gameButton {
  padding: 4px 6px 4px 6px;
  font-size: 16px;
  color: #2c66b2;
  border: 1px solid #2c66b2;
  border-radius: 5px;
  transition: ease-out .3s;
  width: 4em;
  margin-top: 20px;
  margin-left: 2em;
  position: fixed;
}

.gameButton:hover, .skipPromo:hover {
  cursor: pointer;
  opacity: 0.8;
  transition: ease-in .3s;
}

.reEnterButton {
  font-size: 16px;
  transition: ease-in .3s;
  padding: 5px 6px 5px 7px;
  margin-top: 20px;
  margin-left: 8rem;
  width: 4rem;
  color: white;
  border-radius: 5px;
  background: #9c1616;
  position: fixed;
}

.reEnterButton:hover {
  cursor: pointer;
}

.skipPromo {
  position: absolute;
  position: fixed;
  bottom: 30px;
  right: 30px;
  font-size: 20px;
  color: #2c66b2;
  border: 1px solid #2c66b2;
  border-radius: 5px;
  transition: ease-out .3s;
  width: 4em;
  margin-top: 20px;
  margin-left: 2em;
}
