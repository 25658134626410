.selection {
  transition: ease-out .5s;
  display: inline-block;
  margin-top: 30px;
  text-align: center;
}

.dim {
  opacity: 0.5;
}

.hovered {
  cursor: pointer;
}

.imageWrapper {
  border: 1px solid #2c66b2;
  border-radius: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  margin: 15px;
}

.owned {
  filter: grayscale(100%)
}

.selectionImage {
  max-width: 100%;
  max-height: 100%;
  padding: 10px;
  width: 90%;
}

.selectionTitle {
  padding: .5em;
  text-align: left;
  line-height: 4px;
  display: flex;
  font-size: 16px;
  margin-bottom: .5em;
}

.selectionPrice {
  padding: .5em;
  text-align: left;
  line-height: 4px;
  display: flex;
  font-size: 14px;
  color: #ab8432;
}

.selectionData {
  padding: .5em;
  text-align: left;
  line-height: 4px;
  display: flex;
  font-size: 14px;
  color: gray;
}

.selectionDescription {
  padding: .5em;
  text-align: left;
  line-height: 1.3em;
  display: flex;
  font-size: 12px;
  padding: 10px;
  color: #2c66b2;
  width: 140px;
}

.abilityTitle {
  padding: 10px;
  text-align: left;
  line-height: 4px;
  display: flex;
  font-size: 14px;
  margin-top: 8px;
  color: #2c66b2;
}

.itemInfo {
  display: block;
  justify-content: center;
  align-items: center;
  margin: 15px;
}