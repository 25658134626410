.playerDataIcon {
  width: 20px;
  border-radius: 3px;
  margin-right: 3px;
  margin-bottom: -.25em;
}

.playerInfo {
  font-family: 'Pacifico', Sans, Lato, Droid Sans;
  font-size: 14px;
  font-weight: bold;
  color: #2c66b2;
  margin-top: 1.5em;
  margin-bottom: .75rem;
}

.playerInfoWeapon {
  width: 2.5em;
  margin-top: 1em;
  margin-bottom: 1em;
  display: inline-block;
}

.statInfo {
  font-family: 'Pacifico', Sans, Lato, Droid Sans;
  font-size: 14px;
  font-weight: bold;
  color: gray;
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.goldInfo {
  font-family: 'Pacifico', Sans, Lato, Droid Sans;
  font-size: 14px;
  font-weight: bold;
  color: #ab8432;
  padding-top: .75rem;
  padding-bottom: .75em;
}

.gameOverStat {
  width: 10em;
  height: 10em;
  margin-top: 2em;
  margin-left: 1em;
  margin-right: 1em;
  transition: ease-out .5s;
  display: inline-block;
}

.winningScore {
  color: #ffa109;
  font-size: 16px;
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-family: 'Pacifico', Sans, Lato, Droid Sans;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.finalScore {
  color: white;
  font-size: 16px;
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-family: 'Pacifico', Sans, Lato, Droid Sans;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.redModifier {
  color: #9c1616;
  margin-left: 5px;
}

.greenModifier {
  color: rgb(0, 255, 0);
  margin-left: 5px;
}
