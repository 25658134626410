body {
  padding: 0;
  font-family: "Open Sans", "lucida grande", "Segoe UI", arial, verdana, "lucida sans unicode", tahoma, sans-serif;
  background: black;
  position: relative;
  overflow: hidden;
  color: #c1cadb;
}

.game:focus {
  outline: none;
}

.canvas {
  background-size: cover;
}

.placeholderBackground {
  background: url('../images/pilot4.png')
}

.hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out;
}

.visible {
  opacity: 1;
  visibility: visible;
}

.playerWeapon {
  border: 10px solid red;
}
