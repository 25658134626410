.abilityIcon {
  margin-bottom: 10px;
  margin-left: 5px;
  display: inline-block;
}

.abilityIcon:hover {
  cursor: pointer;
}

.icon {
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 50%;
}

.disabled {
  opacity: 0.5;
}

.abilityIconKey {
  font-size: 10px;
  font-weight: bold;
  color: #ab8432;
}
