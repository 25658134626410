.itemSelection {
  height: 80px;
  margin-top: 40px;
  margin-left: 12px;
  margin-right: 12px;
  transition: ease-out .5s;
  display: inline-block;
}

.itemSelection:hover {
  cursor: pointer;
}

.itemImageWrapper {
  border: 1px solid #2c66b2;
  border-radius: 10%;
  padding: 10px;
  margin-bottom: 10px;
  width: 4em;
  height: 4em;
  position: relative;
}

.itemSelectionImage {
  width: 4em;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

