.playerData {
  position: fixed;
  font-size: 16px;
  margin-top: 4em;
  margin-left: 2rem;
  text-align: left;
  display: flex;
}

.playerLevel {
  color: #2c66b2;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
}

.ScoreInfo {
  font-size: 14px;
  color: gray;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.nameInfo {
  font-size: 14px;
  font-weight: bold;
  color: #2c66b2;
  margin-top: 1.5em;
  margin-bottom: .75rem;
}

.playerImage {
  border-radius: 50%;
  width: 4em;
  height: 4em;
  margin-bottom: 1rem;
}

.inactive {
  opacity: '0.5';
}

.playerDataWeapon {
  width: 2.5em;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  display: block;
  border-radius: 10px;
}

.waitCountDown {
  margin-top: .5em;
  font-size: 36px;
  color: red;
  width: 1em;
  height: 1em;
  display: inline-block;
}

.waiting {
  opacity: 0.5;
}

.playerItemData {
  position: relative;
  text-align: center;
  display: inline-block;
}

.playerItemImage {
  width: 2em;
  border-radius: 7px;
}

.faded {
  opacity: .5;
}

.itemCountDown {
  margin-top: 0.85em;
  margin-left: 0.75em;
  color: red;
  z-index: 1;
  position: fixed;
}

.playerShip {
  width: 3em;
  margin-top: 1.5em;
  margin-bottom: 1.25em;
  display: inline-block;
  transform: rotate(270deg);
}

.startButton {
  font-size: 20px;
  transition: ease-in .3s;
  padding-top: 4px;
  padding-bottom: 5px;
  padding-left: 24px;
  margin-top: 10px;
  width: 4rem;
  color: white;
  border-radius: 5px;
  background: #9c1616;
  position: absolute;
}

.startButton:hover {
  cursor: pointer;
}
