
.shipAbilityIcon {
  display: inline-block;
  margin-top: 8px;
  width: 33%;
}

.abilityIcon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  padding-left: .5em;
  padding-right: .5em;
  padding-top: 0.1em;
}

.abilityKey {
  padding-top: .5em;
  font-size: 10px;
  font-weight: bold;
  margin-top: 0.3em;
  color: #ab8432;
  position: absolute;
  display: inline-block;
}
