.gameItemsContainer {
  width: 70%;
  margin-left: 15%;
}

.informationTitle {
  line-height: 35px;
  margin-top: 60px;
  font-size: 20px;
  height: 30px;
  margin-left: 30%;
  width: 40%;
  color: #c1cadb;
  font-weight: bold;
}

.leaderboardTitle {
  margin-top: 1em;
  margin-bottom: 1em;
  font-weight: bold;
  font-size: 30px;
}

.introduction {
  font-size: 20px;
  height: 30px;
  font-size: 18px;
  padding: 0.25em;
  color: #2c66b2;
  margin-top: 2em;
  margin-bottom: 1em;
}

.gameOverText {
  margin-top: 2em;
  font-size: 20px;
  height: 30px;
  margin-left: 40%;
  width: 20%;
}

.instructionControl {
  color: #ffa109;
  margin-right: 0.2em;
}

.creditTitle {
  color: #ffa109;
  margin-right: .5em;
}

.informationText {
  word-wrap: break-word;
  height: 1.25em;
  font-size: 16px;
  padding: 0.25em;
  color: #2c66b2;
}

.notificationText {
  line-height: 25px;
  word-wrap: break-word;
  font-size: 17px;
  color: #2c66b2;
  margin-top: 60px;
  padding-right: 30px;
  padding-left: 30px;
}

.noteText {
  height: 1.25em;
  font-size: 14px;
  padding: 0.25em;
  color: gray;
}

.modal {
  background-color: rgba(0,0,0,0.8);
  animation-name: slideDown;
  -webkit-animation-name: slideDown;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease;
  position: fixed;
  width: 70%;
  height: 90%;
  margin-top: 2%;
  margin-left: 15%;
  overflow: auto;
  border-bottom: 1px solid #ffa109;
  border-top: 1px solid #ffa109;
  z-index: 1;
}

.modalTabs {
  margin-top: 1em;
  margin-bottom: 1em;
}

.selectionButton {
  font-size: 20px;
  transition: ease-in .3s;
  margin-left: 45%;
  padding-bottom: 3px;
  width: 10%;
  color: white;
  border-radius: 5px;
  border: 1px solid #9c1616;
}

.selectionButton:hover {
  background: #9c1616;
  cursor: pointer;
  box-shadow: 0 0 8px 0 rgba(0,0,0, 0.3);
}

.closeButton {
  margin-top: 2em;
  font-size: 20px;
  height: 30px;
  color: #2c66b2;
  margin-left: 40%;
  width: 20%;
  cursor: pointer;
}

.assetLink {
  margin-left: 1em;
  color: #2c66b2;
  transition: ease-in 1.5s;
}

.closeButton:hover {
  cursor: pointer;
  opacity: 0.8;
  transition: ease-in .3s;
  box-shadow: 0 0 8px 0 rgba(0,0,0, 0.3);
}

.selectionText {
  font-size: 20px;
  margin: 1em;
  display: inline-block;
  width: 15%;
  opacity: 0.5;
  padding-bottom: 1em;
  transition: ease-out .5s;
  border-bottom: 1px solid #2c66b2;
}

.selectionText:hover {
  cursor: pointer;
  opacity: 1;
  transition: ease-in .5s;
}

.activeTab {
  opacity: 1;
  border-bottom: 1px solid #ffa109;
}

.exitButton {
  padding: 12px;
  font-size: 16px;
  color: #2c66b2;
  border: 1px solid #2c66b2;
  border-radius: 5px;
  transition: ease-out .3s;
  width: 4em;
  margin-left: 2em;
  position: absolute;
  bottom: 1em;
}

.exitButton:hover {
  cursor: pointer;
  opacity: 0.8;
  transition: ease-in .3s;
}

.formLabel {
  margin-top: 50px;
  display: block;
  font-size: 24px;
  color: #2c66b2;
}

.formInput {
  margin-top: 20px;
  font-size: 20px;
  padding: .15em;
}

.infoImage {
  padding-left: 2em;
  padding-right: 2em;
}

.blueShipInfo {
  transform: rotate(180deg);
}

.informationContext {
  height: 1.25em;
  font-size: 14px;
  padding: 0.25em;
  color: #2c66b2;
}

.red {
  color: #9c1616;
}

.blue {
  color: #2c66b2;
}

.teamLabel {
  margin-top: 30px;
  display: block;
  font-size: 20px;
  color: #2c66b2;
}

.blueBackground {
  background: #2c66b2;
}

.redBackground {
  background: #9c1616;
}

.redTeamButton {
  padding: 4px 6px 4px 6px;
  font-size: 16px;
  color: white;
  border: 1px solid #9c1616;
  border-radius: 5px;
  transition: ease-out .3s;
  display: inline-block;
  width: 3em;
  margin-top: 15px;
  margin-left: 1em;
  margin-right: 1em;
}

.blueTeamButton {
  padding: 4px 6px 4px 6px;
  font-size: 16px;
  color: white;
  border: 1px solid #2c66b2;
  border-radius: 5px;
  transition: ease-out .3s;
  display: inline-block;
  width: 3em;
  margin-top: 15px;
  margin-left: 1em;
  margin-right: 1em;
}

.howToPlayButton, .cinematicButton {
  padding: 6px 8px 6px 8px;
  font-size: 20px;
  color: white;
  border: 1px solid #2c66b2;
  border-radius: 5px;
  transition: ease-out .3s;
  display: inline-block;
  width: 200px;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
}

.startGameButton {
  padding: 6px 8px 6px 8px;
  font-size: 20px;
  color: white;
  border: 1px solid #9c1616;
  border-radius: 5px;
  transition: ease-out .3s;
  display: inline-block;
  width: 6em;
  margin-top: 10px;
  margin-left: 1em;
  margin-right: 1em;
}

.modeGameButton {
  padding: 6px 8px 6px 8px;
  font-size: 20px;
  color: white;
  border: 1px solid #9c1616;
  border-radius: 5px;
  transition: ease-out .3s;
  display: inline-block;
  width: 200px;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
}

.howToPlayButton:hover, .cinematicButton:hover {
  background: #2c66b2;
  color: white;
  cursor: pointer;
}

.redTeamButton:hover, .startGameButton:hover, .modeGameButton:hover {
  background: #9c1616;
  color: white;
  cursor: pointer;
}

.blueTeamButton:hover {
  background: #2c66b2;
  color: white;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
}

.disabled:hover {
  cursor: not-allowed;
}

.howToPlay {
  margin-bottom: 70px;
}

.experiencePoints {
  margin-top: 2em;
}

.informationButton {
  margin-bottom: 2em;
  color: #2c66b2;
  cursor: pointer;
  font-size: 20px;
}

.informationButton:hover {
  color: #2c66b2;
  opacity: 0.8;
  transition: ease-in .3s;
  cursor: pointer;
}


.description {
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  line-height: 24px;
  margin-top: 2em;
  font-size: 18px;
  height: 0.5em;
  color: #c1cadb;
}

.goldContainer {
  margin-left: 50px;
  display: flex;
  align-items: center;
  font-family: 'Pacifico', Sans, Lato, Droid Sans;
  font-size: 20px;
  font-weight: bold;
  color: #ab8432;
}

.goldIcon {
  width: 50px;
}

.playerList {
  margin-top: 30px;
}

.redPlayersList {
  padding: 10px;
  display: inline-block;
  width: 30%;
  margin-left: -15px;
}

.bluePlayersList {
  padding: 10px;
  display: inline-block;
  width: 30%;
}

.playerListItem {
  width: 100%;
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 4px;
  height: 20px;
}

.redBorder {
  border: 1px solid #9c1616;
}

.blueBorder {
  border: 1px solid #2c66b2;
}

.availableSlot {
  border: 1px solid gray;
}

.inviteLink {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 20px;
  padding: 5px;
  color: #2c66b2;
}

.inviteLink:hover {
  transition: ease-in 0.2s;
  color: #ffa109;
  cursor: pointer;
}

@keyframes slideDown {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0%);
	}
}

@-webkit-keyframes slideDown {
	0% {
		-webkit-transform: translateY(-100%);
	}
	100% {
		-webkit-transform: translateY(0%);
	}
}
