.abilityLevel {
  color: #ab8432;
  position: absolute;
  margin-top: 15px;
  margin-left: 35px;
}

.levelUpIcon {
  margin-top: 60px;
  display: inline-block;
  width: 45px;
}

.levelUpIcon:hover {
  cursor: pointer;
}

.levelUpIcon:after {
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #2c66b2;
  margin-top: -19px;
  margin-left: 2px;
  padding: 2px;
  border-color: #2c66b2 transparent #2c66b2 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.abilityIconKey {
  font-size: 10px;
  margin-top: -35px;
  font-weight: bold;
  color: #ab8432;
}

.icon {
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 50%;
  margin-top: -2.36em;
  margin-left: .474em;
}
