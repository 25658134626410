.headerButtons {
  padding: 12px;
  font-size: 16px;
  color: #2c66b2;
  transition: ease-out .3s;
  width: 16em;
  margin-top: 1em;
  position: fixed;
  margin-left: 80%;
}

.headerButton {
  font-size: 16px;
  color: #2c66b2;
  transition: ease-out .3s;
  display: inline-block;
  width: 4em;
}

.headerButton:hover {
  cursor: pointer;
  opacity: 0.8;
  transition: ease-in .3s;
}
